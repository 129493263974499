import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UserUpdateFormComponent } from '../user-update-form/user-update-form.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserManagementService } from 'src/app/services/user-management.service';
import { AuthenticatedUser } from 'src/app/models/user.model';
import { UserStateService } from 'src/app/services/user-state.service';

@Component({
    selector: 'teachica-user-profile-filler',
    templateUrl: './user-profile-filler.component.html',
    styleUrls: ['./user-profile-filler.component.scss'],
    providers: [DialogService],
})
export class UserProfileFillerComponent implements OnInit {
  
    userID!: string; 
    DATA_SECTIONS = {
        personal_information: [
            {
                icon: 'bi bi-person-fill',
                label: 'Full name',
                data: undefined,
            },
            { icon: 'bi bi-person-vcard', label: 'CIN', data: '00 12 45 78' },
        ],
        contact_information: [
            {
                icon: 'bi bi-envelope',
                label: 'Email Address',
                data: undefined,
            },
            { icon: 'bi bi-earbuds', label: 'Phone', data: '95593387' },
        ],
    };

    constructor(
        public dialogService: DialogService,
        public auth0: AuthService,
        private readonly userManagementService: UserManagementService,
        private readonly userstate : UserStateService
    ) { }

    ref: DynamicDialogRef | undefined;

    ngOnInit(): void {
        this.loadUserProfile();
     }


    showUpdateDialogFor(data_type: string) {
        this.ref = this.dialogService.open(UserUpdateFormComponent, {
            header: `Edit ${data_type === 'personal_data' ? 'Personal' : 'Contact'} Information`,
            width: '500px',
            data: { data_type, ...this.DATA_SECTIONS, id: this.userID },
            baseZIndex: 10000,
            closable: true,
            closeOnEscape: true,
        });
        this.ref.onClose.subscribe(() => {
            this.loadUserProfile();
        } );

    }


    loadUserProfile() {
       this.userManagementService.loadUserProfile().subscribe({
              next: (res: AuthenticatedUser) => {
                this.userID = res.id;
                this.DATA_SECTIONS.personal_information[0].data = res.user_profile?.user_first_name;
                this.DATA_SECTIONS.personal_information[1].data = res.user_profile?.user_cin;
                this.DATA_SECTIONS.contact_information[0].data = res.user_email;
                this.DATA_SECTIONS.contact_information[1].data = res.user_profile?.user_phone; 
                this.userstate.setUserProfile(res);               
    }})
    }

}
