import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading.asObservable();

  setLoading(isLoading: boolean): void {
    console.log('isLoading', isLoading);
    
    this._isLoading.next(isLoading);
  }
}
