<section id="login-flex">
    <p-card>
        <header>
            <img src="/assets/logo-teachica.svg" alt="Logo">
        </header>

        <div id="authenticationWarning">
            <p>It appears you are not authenticated.</p>
            <p>Only authorized personal can access this CMS</p>
        </div>

        <button type="submit" (click)="auth.loginWithRedirect()" class="btn btn-primary">Login</button>
    </p-card>
</section>