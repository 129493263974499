import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import { AuthenticatedUser } from '../models/user.model';
import { UserManagementService } from './user-management.service';
import { environment } from 'src/environments/environment';
import { environment as prod } from 'src/environments//environment.prod';


@Injectable({
  providedIn: 'root'
})
export class UserStateService {
/**
   * @description
   * This bahaviorSubject is used to strore local snapshot of user profile
   * This BS is being triggered from
   * src\app\modules\packages\pages\index\index.component.ts
   * 
   */
  private userProfile = new BehaviorSubject<any>({});
  setUserProfile(value: any) { this.userProfile.next(value) }
  getUserProfile() { return this.userProfile.getValue() }
  getUserProfileAsStream(): Observable<any> { return this.userProfile.asObservable() }


}
