import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationComponent } from './components/auth/authentication.component';
import { PageNotFoundComponent } from './modules/shared-components/page-not-found/page-not-found.component';
import { RoleGuard } from './common/guards/role.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'auth',
        component: AuthenticationComponent,
    },
    {
        canActivate: [RoleGuard],
        path: 'tokens',
        loadChildren: () =>
            import('./modules/token-manager/token-manager.module').then(
                (m) => m.TokenManagerModule
            ),
        data: { role: 'admin' },
    },
    {
        canActivate: [RoleGuard],
        path: 'packages',
        loadChildren: () =>
            import(
                './modules/packages/package-manager.module'
            ).then((m) => m.PackageManagerModule),
        data: { role: ['admin', 'teacher'] },
    },
    {
        canActivate: [RoleGuard],
        path: 'correction',
        loadChildren: () =>
            import(
                './modules/vague-correction/vague-correction.module'
            ).then((m) => m.VagueCorrectionModule),
        data: { role: ['admin', 'teacher'] },
    },
    {
        canActivate: [RoleGuard],
        path: 'settings',
        loadChildren: () =>
            import('./modules/settings/settings.module').then(
                (m) => m.SettingsModule
            ),
        data: { role: 'admin' },
    },
    { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
