<teachica-toolbar></teachica-toolbar>
<!-- <div id="progress-indeicator" *ngIf="ls.displayLoader | async">
    <p-progressBar mode="indeterminate" [style]="{ height: '2px' }"></p-progressBar>
</div>
-->
<section id="dashboard-layout">
    <teachica-sidebar></teachica-sidebar>
    <ng-container *ngIf="moduleIsLoading"><teachica-module-loader></teachica-module-loader></ng-container>
    <main *ngIf="!moduleIsLoading">
        <router-outlet></router-outlet>
    </main>
</section>