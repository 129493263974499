import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModuleHeaderComponent } from './module-header/module-header.component';
import { ModuleLoaderComponent } from './module-loader/module-loader.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    ModuleHeaderComponent,
    ModuleLoaderComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    ModuleHeaderComponent,
    ModuleLoaderComponent,
    NotFoundComponent
  ],
})
export class SharedComponentsModule { }
