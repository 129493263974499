import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { environment as prod } from 'src/environments//environment.prod';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  API = environment.base_api;

  constructor(private http: HttpClient) {
    // setting global api route
    this.API = (isDevMode()) ? environment.base_api : prod.base_api;
  }

  updateOneUser(user_id: string, payload: any) {
    const user_sub = JSON.parse(localStorage['authenticated_user'])
    return this.http.get(`${this.API}/users/profile/${user_sub?.sub.split('auth0|')[1]}`)
  }

  

  getStudentsByGrade(grade:string) {
    return this.http.get(`${this.API}/students/grade/${grade}`)
  }

  
}
