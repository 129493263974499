import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { environment as prod } from 'src/environments//environment.prod';
import { AuthService } from '@auth0/auth0-angular';
import { UserManagementApiRoutes } from '../modules/packages/models/api/user-management.api.enum';
import { Observable } from 'rxjs';
import { AuthenticatedUser, UserProfile } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {


  private API: string = '';

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) { 
      this.API = (isDevMode()) ? environment.base_api : prod.base_api;  
    }


  loadUserProfile(): Observable<AuthenticatedUser> {
    const auth0_id = JSON.parse(localStorage['authenticated_user']).sub
    return this.http.get<AuthenticatedUser>(`${this.API}${UserManagementApiRoutes.GetOne(auth0_id)}`);

  }

  updateUserProfile(userId:string,payload: any) : Observable<UserProfile> {
    return this.http.put<UserProfile>(`${this.API}${UserManagementApiRoutes.Update(userId)}`, payload);
   }


}
