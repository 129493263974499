<!-- LOADER -->
<ng-container
    *ngIf="(auth0.isLoading$ | async) || (loadingService.isLoading$ | async)"
>
    <teachica-module-loader></teachica-module-loader>
</ng-container>

<!-- USER NOT LOGGED IN -->
<ng-container
    *ngIf="
        !(auth0.isLoading$ | async) &&
        (auth0.isAuthenticated$ | async) === false
    "
>
    <teachica-authentication></teachica-authentication>
</ng-container>

<!-- USER LOGGED IN -->
<ng-container
    *ngIf="
        !(auth0.isLoading$ | async) &&
        (auth0.isAuthenticated$ | async) === true &&
        !(loadingService.isLoading$ | async)
    "
>
    <teachica-dashboard></teachica-dashboard>
</ng-container>
