import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'teachica-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {

  toggleSidebar: boolean = false;

  constructor(public auth0: AuthService) { }

  logout() {
    localStorage.clear();
  }
}
