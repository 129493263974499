<div id="profile_data_dialog" pDraggable>
    <div id="dialog__body">
        <!-- PERSONAL INFO FORM -->
        <ng-container *ngIf="config.data.data_type === 'personal_data'">
            <form [formGroup]="PERSONAL_INFO_FORM">
                <label htmlFor="username">Full Name</label>
                <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm"
                    id="fullName_input"
                    aria-describedby="full-name-help"
                    formControlName="user_first_name"
                />
                <label htmlFor="username">C.I.N Number</label>
                <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm"
                    id="cin_input"
                    aria-describedby="cin-help"
                    formControlName="user_cin"
                />
            </form>
        </ng-container>

        <!-- PERSONAL INFO FORM -->
        <ng-container *ngIf="config.data.data_type === 'contact_data'">
            <form [formGroup]="CONTACT_INFO_FORM">
                <label htmlFor="username">Email Address</label>
                <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm"
                    id="email_input"
                    aria-describedby="email-help"
                    formControlName="email"
                />
                <label htmlFor="username">Phone Number</label>
                <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm"
                    id="phone_input"
                    aria-describedby="phone-help"
                    formControlName="user_phone"
                />
            </form>
        </ng-container>
    </div>

    <p-divider></p-divider>

    <div id="dialog__footer">
        <p-button
            label="Cancel"
            styleClass="p-button-outlined p-button-secondary"
            (click)="this.dialogRef.close()"
        ></p-button>
        <button
            pButton
            pRipple
            label="Save Changes"
            class="p-button-primary submitButton"
            (click)="updateUserProfile()"
        ></button>
    </div>
</div>
