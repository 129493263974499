import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return (
      route.data['role']
        .includes(JSON.parse(localStorage['authenticated_user']).email
          .toString()
          .split('@')[1]
          .split('.')[0])
    )
  }
}
