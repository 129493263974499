// auth.interceptor.ts

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return this.authService.getAccessTokenSilently().pipe(
            switchMap((accessToken) => {
                if (accessToken) {
                    // Add the access token to the request headers.
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
                return next.handle(request);
            })
        );
    }
}
