import { Component } from '@angular/core';

@Component({
  selector: 'teachica-module-loader',
  templateUrl: './module-loader.component.html',
  styleUrls: ['./module-loader.component.scss']
})
export class ModuleLoaderComponent {

}
