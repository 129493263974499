import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public auth0: AuthService, public readonly loadingService:LoaderService) { }
}