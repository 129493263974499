import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PrimeNgModule } from './primeng.module';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import * as Sentry from "@sentry/angular";
// CUSTOM
// **** Components
import { AuthenticationComponent } from './components/auth/authentication.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { ToolbarComponent } from './components/layout/toolbar/toolbar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserProfileFillerComponent } from './components/user-profile/user-profile-filler/user-profile-filler.component';
// **** Services
import { InterceptorService } from './services/interceptor.service';
// **** Modules
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { PageNotFoundComponent } from './modules/shared-components/page-not-found/page-not-found.component';
import { UserUpdateFormComponent } from './components/user-profile/user-update-form/user-update-form.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { Router } from '@angular/router';

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        ToolbarComponent,
        AuthenticationComponent,
        DashboardComponent,
        PageNotFoundComponent,
        UserProfileFillerComponent,
        UserUpdateFormComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        PrimeNgModule,
        SharedComponentsModule,
        //Import the module into the application, with configuration
        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: environment.auth0.audience,
            },
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: true,
            }),
          }, {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
