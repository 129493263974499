import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, Event } from '@angular/router';

@Component({
  selector: 'teachica-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  moduleIsLoading = false;

  constructor(
    public ls: LoaderService,
    private router: Router
  ) {
    this.router.events.subscribe((e: Event) => {
      if (e instanceof RouteConfigLoadStart)
        this.moduleIsLoading = true;
      if (e instanceof RouteConfigLoadEnd)
        this.moduleIsLoading = false;
    });
  }
}

