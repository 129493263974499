<div id="no-data">
    <!-- NO FOUND || EMPTY SET -->
    <ng-container *ngIf="!(type === 'page')">
        <img src="../../../../../assets/meta/data-not-found.svg" alt="data-not-found">
    </ng-container>

    <!-- PAGE NOT FOUND -->
    <ng-container *ngIf="type === 'page'">
        <img src="../../../../../assets/meta/page-not-found.svg" alt="data-not-found">
    </ng-container>
</div>