<ng-container *ngIf="auth0.user$ | async as userData">
    <div id="user_data_container_header">
        <img [src]="userData?.picture" alt="user profile picture" />
        <h6>{{ userData?.email }}</h6>
    </div>
    <hr />
    <!-- PROFILE SECTION | USER INFO -->
    <div id="user_data_container_contact_info">
        <div class="contact_section_header">
            <h6>Personal information</h6>
            <button (click)="showUpdateDialogFor('personal_data')">edit</button>
        </div>
        <ng-container *ngFor="let data of DATA_SECTIONS.personal_information">
            <div class="contact_info_item">
                <span class="iconContainer"><i [class]="data.icon"></i></span>
                <div class="actualDataContainer">
                    <h3>{{ data.label }}</h3>
                    <p>{{data.data || 'N.A' }}</p>
                </div>
            </div>
        </ng-container>
        <!-- ENd OF CONTACT INFO -->
    </div>
    <hr />
    <!-- PROFILE SECTION | CONTACT INFO -->
    <div id="user_data_container_contact_info">
        <div class="contact_section_header">
            <h6>Contact information</h6>
            <button (click)="showUpdateDialogFor('contact_data')">edit</button>
        </div>
        <ng-container *ngFor="let data of DATA_SECTIONS.contact_information">
            <div class="contact_info_item">
                <span class="iconContainer"><i [class]="data.icon"></i></span>
                <div class="actualDataContainer">
                    <h3>{{ data.label }}</h3>
                    <p>{{ data.data || 'N.A' }}</p>
                </div>
            </div>
        </ng-container>
        <!-- ENd OF CONTACT INFO -->
    </div>
</ng-container>
