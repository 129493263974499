<div id="main-toolabr">
    <div class="content">
        <img src="../../../../assets/logo-teachica.svg" width="50px" />
        <span>TEACHICA</span>
    </div>

    <!-- USED TO TRIGGER OFF CANVAS SIDEBAR -->
    <img
        [src]="(auth0.user$ | async)?.picture"
        alt="user profile picture"
        id="profilePictureDataToggle"
        (click)="toggleSidebar = true"
    />
</div>

<!-- SIADEBAR SIDEBAR -->
<p-sidebar [(visible)]="toggleSidebar" position="right">
    <teachica-user-profile-filler></teachica-user-profile-filler>
    <hr />
    <button class="btn" (click)="auth0.logout(); logout()">
        <i class="bi bi-box-arrow-right"></i>
        <span>Sign out</span>
    </button>
</p-sidebar>
