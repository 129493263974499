import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';

import { UserManagementService } from 'src/app/services/user-management.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'teachica-user-update-form',
    templateUrl: './user-update-form.component.html',
    styleUrls: ['./user-update-form.component.scss'],
})
export class UserUpdateFormComponent implements OnInit {
    PERSONAL_INFO_FORM!: FormGroup;
    CONTACT_INFO_FORM!: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public config: DynamicDialogConfig,
        public dialogRef: DynamicDialogRef,
        private readonly userManagementService: UserManagementService,
        private usersService: UsersService
    ) { }

    ngOnInit(): void {
        if (this.config.data.data_type === 'personal_data')
            this.constructPersonalInformationForm();
        else this.constructContactInformationForm();
    }

    constructPersonalInformationForm() {
        this.PERSONAL_INFO_FORM = this.formBuilder.group({
            user_first_name: [
                this.config.data.personal_information[0].data ? this.config.data.personal_information[0].data : 'N.A',
                Validators.required,
            ],
            user_cin: [
                this.config.data.personal_information[0].data ? this.config.data.personal_information[0].data : 'N.A',
                Validators.required,
            ],
        });
    }
    constructContactInformationForm() {
        this.CONTACT_INFO_FORM = this.formBuilder.group({
            email: [
                this.config.data?.contact_information[0].data ? this.config.data?.contact_information[0].data : 'N.A',
                Validators.required,
            ],
            user_phone: [
                this.config.data?.contact_information[1].data ? this.config.data?.contact_information[1].data : 'N.A',
                Validators.required,
            ],
        });
    }

    updateUserProfile() {
        const formValue = this.config.data.data_type === 'personal_data'
            ? this.PERSONAL_INFO_FORM.value
            : this.CONTACT_INFO_FORM.value

        this.userManagementService.updateUserProfile(
            this.config.data.id,
            formValue
        ).subscribe((res) => {
            this.dialogRef.close();
        }
        );
    }

    save() {
        if (this.CONTACT_INFO_FORM && this.CONTACT_INFO_FORM.valid)
            this.usersService.updateOneUser('', this.CONTACT_INFO_FORM.value)

        if (this.PERSONAL_INFO_FORM && this.PERSONAL_INFO_FORM.valid)
            this.usersService.updateOneUser('', this.PERSONAL_INFO_FORM.value)
    }
}

