import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'teachica-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  sidebarElements = [
    { icon: 'bi bi-house-door', label: 'Home', routerLink: ['/'] },
    { icon: 'bi bi-5-circle', label: 'Sourdi', routerLink: ['/tokens'] },
    { icon: 'bi bi-box-seam', label: 'Packages', routerLink: ['/packages'] },
    { icon: 'bi bi-box-seam', label: 'Correction', routerLink: ['/correction'] },
    { icon: 'bi bi-gear', label: 'Settings', routerLink: ['/settings'] },
  ];

  constructor() { }

  ngOnInit(): void { }

}
